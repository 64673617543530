import {
  genDefaultItem,
  genDefaultItemWithInfo,
  genMenuItemCopy,
  genMenuItemInfo
} from '@/utils/list-generators';
import { convertDate } from '@/utils';
import { useCopy } from '@/compositions/copy';
import { usePopup } from '@/compositions/popup';
import { getControlIcon } from '@/modules/linked-objects/ui/linked-object-card/controls/control-icons';
import {toFixed} from "ol/math";

const historyItems = {
  monitoringItem: item => {
    const isAlert = item.node.property === 'Alert';
    const isTriggered = item.node.value === 'TRIGGERED';
    const hasAlert = isAlert && item.node.value !== 'OFF';

    return genDefaultItem({
      title: `${item.node.value}`,
      subTitle: convertDate(item.node.recordedAt),
      iconColor: isTriggered ? 'red' : undefined,
      icon: hasAlert ? '$alert_on' : ''
    });
  },
  geoItem: item => {

    return genDefaultItem({
      title: `${toFixed(item.node.value?.lon, 6)}; ${toFixed(item.node.value?.lat,6)};  ${toFixed(item.node.value?.alt,6)}`,
      subTitle: convertDate(item.node.recordedAt),
      icon: 'mdi-map-marker',
    });
  },
  propertyItem: item => {
    const popup = usePopup();
    const { copyWithAlert } = useCopy();
    const isJson = typeof item.node.value === 'object';
    let value = 'n/a';
    let author = 'n/a';

    if (item.node.userByBy?.mName) {
      author = item.node.userByBy?.mName;
    }

    if (item.node.value !== null) {
      value = isJson ? JSON.stringify(item.node.value) : item.node.value;
    }

    return genDefaultItem({
      title: `${item.node.property}: ${value}`,
      actions: [
        genMenuItemInfo(() => {
          popup.open({
            component: () => import('@/components/popup/PropertyInfo.vue'),
            props: {
              property: item.node
            }
          });
        }),
        genMenuItemCopy(async () => copyWithAlert(value))
      ],
      subTitle: `${convertDate(item.node.recordedAt)} by ${author}`,
      showAvatar: false
    });
  },
  controlItem: control => {
    const popup = usePopup();

    return genDefaultItemWithInfo({
      title: control.node.name,
      subTitle: `${convertDate(control.node.createdAt)} by ${
        control.node.caller.mName
      }`,
      action: () => {
        popup.open({
          component: () => import('@/components/popup/ControlInfo.vue'),
          props: {
            controlItem: control
          }
        });
      },

      icon: getControlIcon(
        control.node.ack,
        control.node.done,
        control.node.error
      )
    });
  }
};

export default historyItems;
